var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form)?_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"card-title-desc"},[_vm._v(" Chỉnh sửa thông tin khách hàng xuất hóa đơn nếu cần ")]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tên người gửi")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_fn),expression:"form.sender_fn"}],staticClass:"form-control",class:{
          'is-invalid': _vm.typesubmit && _vm.$v.form.sender_fn.$error,
        },attrs:{"type":"text","placeholder":"Type something","name":"sender_fn"},domProps:{"value":(_vm.form.sender_fn)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_fn", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.sender_fn.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sender_fn.required)?_c('span',[_vm._v("Giá trị nhập vào là cần thiết.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Số điện thoại người gửi")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_phone),expression:"form.sender_phone"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.sender_phone.$error,
          },attrs:{"type":"text","name":"sender_phone","placeholder":"Enter only digits"},domProps:{"value":(_vm.form.sender_phone)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_phone", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.sender_phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sender_phone.required)?_c('span',[_vm._v("Giá trị nhập vào là cần thiết")]):_vm._e(),(!_vm.$v.form.sender_phone.numeric)?_c('span',[_vm._v("Giá trị nhập vào phải là chữ số.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("E-Mail người gửi")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_email),expression:"form.sender_email"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.sender_email.$error,
          },attrs:{"type":"email","name":"sender_email","placeholder":"Nhập địa chỉ e-mail"},domProps:{"value":(_vm.form.sender_email)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_email", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.sender_email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sender_email.required)?_c('span',[_vm._v("Dữ liệu nhập vào là cần thiết.")]):_vm._e(),(!_vm.$v.form.sender_email.email)?_c('span',[_vm._v("Email không phù hợp.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Địa chỉ người gửi")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sender_address),expression:"form.sender_address"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.sender_address.$error,
          },attrs:{"type":"text","name":"sender_address","placeholder":"Nhập địa chỉ người gửi"},domProps:{"value":(_vm.form.sender_address)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "sender_address", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.sender_address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.sender_address.required)?_c('span',[_vm._v("Giá trị nhập vào là được yêu cầu.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tên người nhận")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_fn),expression:"form.recipient_fn"}],staticClass:"form-control",class:{
          'is-invalid': _vm.typesubmit && _vm.$v.form.recipient_fn.$error,
        },attrs:{"type":"text","placeholder":"Nhập tên người nhận","name":"recipient_fn"},domProps:{"value":(_vm.form.recipient_fn)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_fn", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.recipient_fn.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.recipient_fn.required)?_c('span',[_vm._v("Giá trị nhập vào là cần thiết.")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Số điện thoại người nhận")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_phone),expression:"form.recipient_phone"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.recipient_phone.$error,
          },attrs:{"type":"text","name":"recipient_phone","placeholder":"Nhập số điện thoại người nhận"},domProps:{"value":(_vm.form.recipient_phone)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_phone", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.recipient_phone.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.recipient_phone.required)?_c('span',[_vm._v("Giá trị nhập vào là cần thiết")]):_vm._e(),(!_vm.$v.form.recipient_phone.numeric)?_c('span',[_vm._v("Giá trị nhập vào phải là chữ số.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("E-Mail người nhận")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_email),expression:"form.recipient_email"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.recipient_email.$error,
          },attrs:{"type":"email","name":"recipient_email","placeholder":"Nhập địa chỉ e-mail người nhận"},domProps:{"value":(_vm.form.recipient_email)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_email", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.recipient_email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.recipient_email.required)?_c('span',[_vm._v("Dữ liệu nhập vào là cần thiết.")]):_vm._e(),(!_vm.$v.form.recipient_email.email)?_c('span',[_vm._v("Email không phù hợp.")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Địa chỉ người nhận")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recipient_address),expression:"form.recipient_address"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.form.recipient_address.$error,
          },attrs:{"type":"text","name":"recipient_address","placeholder":"Nhập địa chỉ người nhận"},domProps:{"value":(_vm.form.recipient_address)},on:{"change":_vm.customerChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recipient_address", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.form.recipient_address.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.recipient_address.required)?_c('span',[_vm._v("Giá trị nhập vào là được yêu cầu.")]):_vm._e()]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }