<template>
  <div>
    <div id="show-buystore">
      <h5 class="h6">Điền thông tin để tạo hóa đơn</h5>
      <hr />
      <div class="my-3">
        <label for="Tên mục hàng">Tên mục hàng mua hộ</label>
        <b-form-input
          v-model="buy_name"
          type="text"
          debounce="300"
          class="form-control"
          disabled=""
          name="item_name"
          placeholder="Nhập tên mục mua hộ"
          :class="{
            'is-invalid': errorSubmit && $v.buy_name.$error,
          }"
        >
        </b-form-input>
        <div v-if="errorSubmit && $v.buy_name.$error" class="invalid-feedback">
          <span v-if="!$v.buy_name.required">Bạn cần nhập tên mục hàng.</span>
        </div>
      </div>

      <div class="mb-3">
        <label for="Tên khối lượng hàng">Giá trị đơn hàng mua hộ lưu kho </label>
        <b-form-input
          type="number"
          class="form-control"
          name="item_name"
          placeholder="Nhập tổng số tiền"
          v-model="buy_lb"
          :class="{
            'is-invalid': errorSubmit && $v.buy_lb.$error,
          }"
          @change="tinhtien()"
        >
        </b-form-input>

        <div v-if="errorSubmit && $v.buy_lb.$invalid" class="invalid-feedback">
          <span v-if="!$v.buy_lb.required"
            >Bạn cần nhập khối lượng hàng mua hộ.</span
          >
        </div>
      </div>
      <h6 class="text-sm-end">
        Phí mua hộ:
        <span id="result-buy">
          {{ price_buy }}(%) x {{ buy_lb }} = {{ subtotal_buy }}</span
        >
      </h6>

      <div class="my-3">
        <input type="checkbox" v-model="isDiscountBuy" @change="tinhtien" />
        Khuyến mãi giảm giá
        <div class="buystore-disc-show mt-3" v-if="isDiscountBuy">
          <div class="row">
            <div class="col-4">
              <p class="text-muted">Phí dịch vụ</p>
              <div class="input-group mb-3">
                <div class="input-group-prepend mx-2">
                  <div class="input-group-text">$</div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_1"
                  :value="subtotal_buy"
                  disabled=""
                />
                <div class="input-group-append mx-2">
                  <span class="input-group-text"> - </span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <p class="text-muted">
                Tổng khuyễn mãi {{ disc_buy }}({{ disc_unit }})
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_2"
                  :value="disc_count_buy"
                  disabled=""
                />
                <div class="input-group-append">
                  <span class="input-group-text mx-2">=</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <p class="text-muted">Tổng phí dịch vụ</p>
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_4"
                  :value="total_buy"
                  disabled=""
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="text-sm-end">
              Tổng phí lưu kho sau khuyễn mãi :
              <span
                >{{ subtotal_buy }} - {{ disc_count_buy }} =
                {{ total_buy }}</span
              >
            </h6>
          </div>
        </div>
      </div>

      <h6>
        Tổng phí dịch vụ mua hộ lưu kho : {{
          total_buy 
        }}
      </h6>
    </div>
    <hr />

    <div class="p-2 mt-3">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Hóa đơn</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td colspan="2" class="text-center">
              <b> {{ service_name }}</b>
            </td>
          </tr>

          <tr>
            <td>Name Buy</td>
            <td class="text-sm-end">{{ buy_name }}</td>
          </tr>
          <tr>
            <td>Value Buy</td>
            <td class="text-sm-end">{{ buy_lb }}</td>
          </tr>
          <tr>
            <td>Service Charge Buy</td>
            <td class="text-sm-end">{{ price_buy }} %</td>
          </tr>
          <tr>
            <td>Subtotal Buy</td>
            <td class="text-sm-end">{{ subtotal_buy }}</td>
          </tr>
          <tr>
            <td>Discount Buy (-)</td>
            <td class="text-sm-end">{{ disc_count_buy }}</td>
          </tr>
          <tr>
            <td>Total (=)</td>
            <td class="text-sm-end">{{ total_buy }}</td>
          </tr>

          <tr>
            <td colspan="2"><hr /></td>
          </tr>
          <tr>
            <td>Total (=)</td>
            <td class="text-sm-end">{{ total_buy }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row font-size-14">
      <div class="col">
        <strong> Billing (Fees + Value Store) : {{ total }}</strong>
      </div>
    </div>

    <hr />
    <p class="text-danger m-0">
      <strong>Lưu Ý Quan trọng</strong> Hóa đơn tạo ra sẽ không thể sửa chữa, hãy kiểm tra kĩ trước khi bấm nút tạo hóa đơn.
    </p>

    <button
      type="button"
      @click="$router.push({ path: '/danh-sach-don-hang' })"
      class="mx-3 mt-3 btn btn-primary"
    >
      Danh sách đơn hàng
    </button>
    <button type="button" @click="createOrder" class="mt-3 btn btn-success">
      Tạo hóa đơn
    </button>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      errorSubmit: false,
      noted: "",
      isTax: false,
      price: null,
      isDiscountBuy: false,
      isDiscountStore: false,
      opition: null,
      buy_name: "Products Total",
      price_buy: 0,
      buy_lb: 0,
      disc_buy: 0,
      disc_unit: "%",
      subtotal_buy: 0,
      disc_count_buy: 0,
      total_buy: 0,
      total: 0,
      salary: 0,
      service_name: "",
    };
  },
  validations: {
    buy_name: {
      required,
    },

    buy_lb: {
      required,
    },
    noted: {
      // required,
    },
  },
  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getCustomer",
    ]),
  },
  methods: {
    tinhtien() {
      this.buy_lb = Number(this.buy_lb);
   

      if (!this.buy_lb) {
        this.$toasted.error(
          "Vui lòng nhập tổng giá trị đơn hàng mua hộ và lưu kho"
        );
        return;
      }

      let price_buy = this.buy_lb * (this.price_buy / 100);
      this.subtotal_buy = parseFloat(price_buy.toFixed(2));

      if (this.isDiscountBuy) {
        if (this.disc_unit == "%") {
          this.disc_count_buy = (
            (this.disc_buy / 100) *
            this.subtotal_buy
          ).toFixed(2);
          let total_buy = (this.subtotal_buy - this.disc_count_buy).toFixed(2);
          this.total_buy = parseFloat(total_buy);
        } else {
          this.disc_count_buy = this.disc_buy;
          let total_buy = (this.subtotal_buy - this.disc_count_buy).toFixed(2);
          this.total_buy = parseFloat(total_buy);
        }
      } else {
        this.disc_count_buy = 0;
        this.total_buy = this.subtotal_buy;
      }


      let total = parseFloat((this.total_buy).toFixed(6));
      this.total =
        this.total_buy > 0
          ? Number(total) + Number(this.buy_lb)
          : Number(total) + Number(this.buy_lb);
    },
    createOrder() {
      this.errorSubmit = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorSubmit = true;
        this.$toasted.error("Vui lòng sửa biểu mẫu trước khi xuất hóa đơn");
        return;
      }

      if (!this.buy_lb) {
        this.$toasted.error(
          "Vui lòng nhập tổng giá trị đơn hàng mua hộ và lưu kho"
        );
        return;
      }

      if (this.total > 0) {
        if (user) {
          if (this.getCustomer) {
            if (this.total < 0) {
              this.$toasted.error("Tổng phí không thể thấp hơn 0");
              return false;
            }

            // tính lương cho nhân viên
            let sBuy = (
              this.buy_lb *
              (this.opition.salary_buy / 100)
            ).toFixed(2);
         
            this.salary = Number(sBuy);
            // tính lương cho nhân viên

            let payload = {
              ...this.getCustomer,
              service_name: this.service_name,
              type: 6,

              opition: this.opition,
              buy_name: this.buy_name,
 
              price_buy: this.price_buy,

              buy_lb: this.buy_lb,

              disc_buy: this.disc_buy,
    
              disc_unit: this.disc_unit,
              subtotal_buy: this.subtotal_buy,
              disc_count_buy: this.disc_count_buy,
              total_buy: this.total_buy,
              products: [
                {
                  name: this.buy_name,
                  lb: this.buy_lb,
                  disc: this.disc_buy,
                  disc_unit: this.disc_unit,
                  subtotal: this.subtotal_buy,
                  discount: this.disc_count_buy,
                  total: this.total_buy,
                }
              ],
              shipping_fee: Number(this.price_buy),
              fee_unit: this.disc_unit,
              totalLB:  Number(this.buy_lb),
              service_price: Number(this.total_buy) ,
              subTotal: Number(this.subtotal_buy),
              discount: Number(this.disc_count_buy),
              fee_unit: this.getService.disc_unit,
              total: this.total,
              customs_duty: 0,
              note: this.noted,
              salary: this.salary,
            };

            axios({
              url: process.env.VUE_APP_SERVER_URL + "/api/bill/add",
              method: "post",
              headers: {
                authorization: user.token,
              },
              data: payload,
            })
              .then(({ data }) => {
                this.$toasted.success("Hóa đơn đã được xử lý thành công");
                return data;
              })
              .catch((error) => {
                console.log(error);
                error && error.message && error.response.data
                  ? this.$toasted.error(
                      error.response ? error.response.data : error.message
                    )
                  : this.$toasted.error("Lỗi không xác định xảy ra.");
              });
          } else {
            this.$toasted.error(
              "Chưa tải được thông tin khách hàng vui lòng reload lại trang sau đó xuất hóa đơn lại."
            );
          }
        } else {
          this.$toasted.error("Vui lòng đăng nhập để tiếp tục");
        }
      } else {
        this.$toasted.error("Tổng giá trị đơn hàng cần lớn hơn 0");
      }
    },
  },
  mounted() {
    if (this.getService) {
      this.service_name = this.getService.name;
      this.opition = this.getService.buystore;
      this.price_buy = this.opition.price_buy;
      this.disc_unit = this.opition.disc_unit;
      this.disc_buy = this.opition.disc_buy;
    }
  },
};
</script>