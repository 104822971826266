<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import orderFormInfo from "@/components/order/form-info";
import selectService from "@/components/handler/index";
import usvn from "@/components/handler/usvn";
import vnus from "@/components/handler/vnus";
import usus from "@/components/handler/usus";
import seaway from "@/components/handler/seaway";
import money from "@/components/handler/money";
import buystore from "@/components/handler/buystore";
import shopping from "@/components/handler/shopping";


import { mapGetters, mapState } from "vuex";

let user = JSON.parse(localStorage.getItem("user"));

/**
 * Invoice-detail component
 */
export default {
  page: {
    title: "Chi tiết đơn hàng",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    orderFormInfo,
    selectService,
    usvn,
    vnus,
    usus,
    seaway,
    money,
    buystore,
    shopping
  },
  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getCustomer",
    ]),
  },
  data() {
    return {

      title: "Chi tiết đơn hàng",
      items: [
        {
          text: "Trang chủ",
          href: "/",
        },
        {
          text: "Chi tiết đơn hàng",
          active: true,
        },
      ],
      tabIndex: 1,

      order: null,

      form: null,
    };
  },

  methods: {
    preSubmit() {
      Object.assign({}, this.form);
      // let _form = document.getElementById("form-data-product");
      // let formData = new FormData(_form);

      // for (var pair of formData.entries()) {
      //   console.log(`${pair[0]} -- ${pair[1]}`);
      // }

      if (service) {
        switch (service.type) {
          case 1:
            break;
        }
      }
    },
    tabSelect(tab){
        this.tabIndex = tab;
    }
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/api/order/detail",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          this.order = data;
          this.form = {
            sender_fn: data.sender_fn,
            sender_phone: data.sender_phone,
            sender_email: data.sender_email,
            sender_address: data.sender_address,
            recipient_fn: data.recipient_fn,
            recipient_phone: data.recipient_phone,
            recipient_address: data.recipient_address,
            customer: data.customer,
            invoice: data.invoice,
          };
            this.$store.commit("service/setOrderCustomer", Object.assign({},this.form));
        } else {
          this.$toasted.error("Không tìm thấy đơn hàng phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.$toasted.error("Không tìm thấy đơn hàng phù hợp");
        this.$router.push("/");
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form id="form-data-product">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <p class="card-title-desc">
               Click các TAB để hoàn thành nghiệp vụ.
              </p>
              <b-tabs
                v-model="tabIndex"
                justified
                nav-class="nav-tabs-custom"
                content-class="p-3 text-muted"
              >
                <b-tab active>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="fas fa-home"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"
                      >Thông tin đơn hàng</span
                    >
                  </template>

                  <orderFormInfo :form="form" />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block">Chọn dịch vụ</span>
                  </template>
                  <!-- FORM INPUT -->
                  <selectService />
                  <!-- service us -vn -->
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-envelope"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"
                      >Khai báo hàng hóa</span
                    >
                  </template>

                  <div class="service-products">
                    <div v-if="getService">
                      <!-- START SERVICE 1 -->

                      <div v-if="getService.type == 1">
                        <div v-if="getOpition">
                          <usvn @nextTab="tabSelect"/>
                        </div>
                        <div v-else>Vui lòng chọn nơi đến</div>
                      </div>
                      <!-- END SERVICE 1-->
                      <div v-if="getService.type == 2">
                        <vnus />
                      </div>

                      <div v-if="getService.type == 3">
                        <usus />
                      </div>

                      <div v-if="getService.type == 4">
                        <seaway />
                      </div>

                      <div v-if="getService.type == 5">
                        <div v-if="getOpition">
                          <money />
                        </div>
                        <div v-else>Vui lòng chọn nơi đến</div>
                      </div>

                      <div v-if="getService.type == 6">
                        <buystore />
                      </div>

                      <div v-if="getService.type == 7">
                        <shopping />
                      </div>
                    </div>
                    <div v-else>Vui lòng chọn dịch vụ trước</div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- end row -->
  </Layout>
</template>