<template>
  <div id="service">
    <div class="mb-3">
      <label class="col-form-label">Chọn dịch vụ</label>
      <div>
        <select
          class="form-control"
          v-model="service"
          name="service"
          v-on:change="onChangeService(service)"
        >
          <option :value="null">Chọn dịch vụ</option>
          <option :value="_serv" v-for="_serv in services" :key="_serv.type">
            {{ _serv.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="mb-3" v-if="service">
      <div v-if="service.type == 1">
        <UsvnOpition :service="service" @setopition="setopition" />
        <input type="hidden" v-model="opition" name="service_opition" />
      </div>

      <div v-if="service.type == 2">
   
        <hr />
        <div class="row">
          <div class="col">
            <h6>{{ service.vnus[0].name }}</h6>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Cân nặng</th>
                  <th>Giá</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in service.vnus[0].values"
                  :key="index + item.weight"
                >
                  <td>{{ item.weight }}</td>
                  <td>{{ item.price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">
            <h6>{{ service.vnus[1].name }}</h6>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Cân nặng</th>
                  <th>Giá</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in service.vnus[1].values"
                  :key="index + item.weight"
                >
                  <td>{{ item.weight }}</td>
                  <td>{{ item.price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="service.type == 5">
        <moneyOpition :service="service" @setopition="setopition" />
        <input type="hidden" v-model="opition" name="service_opition" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UsvnOpition from "@/components/handler/usvn-opition";
import moneyOpition from "@/components/handler/money-opition";
let user = JSON.parse(localStorage.getItem("user"));

export default {
  data() {
    return {
      services: [],
      service: null,
      opition: null,
      price: null,
    };
  },
  components: { UsvnOpition, moneyOpition },
  methods: {
    onChangeService(service) {
        this.$store.commit("service/setService", Object.assign({},service));
        this.$store.commit("service/setOpition", null)
    },
    setopition(value) {
      this.opition = value;
      this.$store.commit("service/setOpition",  Object.assign({},value))
    }
  },

  created() {
    let _id = this.$route.query.id;
    axios({
      url: process.env.VUE_APP_SERVER_URL + "/service/list",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    }).then(({ data }) => {
      if (data) {
        this.services = data;
        this.$store.commit('service/setServices', data)
      }
    });
  },
};
</script>