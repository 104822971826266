<template>
  <div class="mb-3" v-if="service">
      <select
        class="form-control"
        v-model="option"
        @change="setOpition"
      >
        <option :value="null">Chọn nơi đến</option>
        <option :value="item"  v-for="item in service.money" :key="item.name">
          {{ item.name }}
        </option>
      </select> 
  </div>
</template>
<script>
export default {
  props: {
    service: Object,
  },
  data() {
    return {
      option: null,
    };
  },
  methods:{
    setOpition(){
      this.$emit('setopition', this.option)
    }
  }
};
</script>