<template>
  <div class="mb-3" v-if="service">

     <select
        class="form-control mb-3"
        v-model="supplier"
        @change="setSupplier"
      >
        <option :value="null">Chọn giá áp dụng</option>
        <option :value="item"  v-for="item in service.usvn" :key="item.name">
          {{ item.name }}
        </option>
      </select> 

  
      <select
        v-if="supplier"
        class="form-control"
        v-model="option"
        @change="setOpition"
      >
        <option :value="null">Chọn nơi đến</option>
        <option :value="item"  v-for="item in supplier.supplier" :key="item.name">
          {{ item.name }}
        </option>
      </select> 
  </div>
</template>
<script>
export default {
  props: {
    service: Object,
  },
  data() {
    return {
      supplier: null,
      option: null,
    };
  },
  methods:{
    setOpition(){
      this.$emit('setopition', this.option)
    },
    setSupplier(){
      this.$toasted.show("Vui lòng chọn nơi đến")
      this.option = null;
    }
  }
};
</script>