<template>
  <div>
    <div id="show-vnus">
      <h5 class="h6">Chọn giá áp dụng cho đơn hàng</h5>
      <b-form-radio
        name="service_provide"
        v-for="item in getService.vnus"
        :key="item.name"
        v-model="opition"
        :value="item"
        plain
        @change="tinhtien()"
        :class="{
          'is-invalid': errorSubmit && $v.opition.$error,
        }"
      >
        {{ item.name }}
      </b-form-radio>

      <div v-if="errorSubmit && $v.opition.$error" class="invalid-feedback">
        <span v-if="!$v.opition.required"
          >Bạn cần chọn danh mục giá áp dụng cho đơn hàng.</span
        >
      </div>
      <hr />

      <div class="my-3">
        <label for="Tên mục hàng">Tên mục hàng</label>
        <b-form-input
          v-model="vnus.name"
          type="text"
          debounce="300"
          class="form-control"
          name="item_name"
          placeholder="Nhập tên mục hàng"
          :class="{
            'is-invalid': errorSubmit && $v.vnus.name.$error,
          }"
        >
        </b-form-input>

        <div v-if="errorSubmit && $v.vnus.name.$error" class="invalid-feedback">
          <span v-if="!$v.vnus.name.required">Bạn cần nhập tên mục hàng.</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="Tên khối lượng hàng">Khối lượng vận chuyển</label>
        <b-form-input
          type="text"
          class="form-control"
          name="item_name"
          placeholder="Nhập khối lượng"
          v-model="vnus.lb"
          @keyup="tinhtien()"
          :class="{
            'is-invalid': errorSubmit && $v.vnus.lb.$error,
          }"
        >
        </b-form-input>
        <div v-if="errorSubmit && $v.vnus.lb.$error" class="invalid-feedback">
          <span v-if="!$v.vnus.lb.required"
            >Bạn cần nhập khối lượng cần vận chuyển.</span
          >
        </div>
      </div>
      <h6 class="text-sm-end">
      Khối lượng
        <span id="result"
          >  {{ vnus.lb }} => Giá {{ price }} = {{ vnus.subTotal }}</span
        >
      </h6>
      <div class="my-3">
        <input type="checkbox" v-model="isDiscount" @change="tinhtien" />
        Khuyến mãi giảm giá
        <div class="vnus-disc-show mt-3" v-if="isDiscount">
          <div class="row">
            <div class="col-3">
              <p class="text-muted">Phí gửi</p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_1"
                  :value="vnus.subTotal"
                  disabled=""
                />
              </div>
            </div>
            <div class="col-3">
              <p class="text-muted">Khuyến mãi</p>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_2"
                  :value="vnus.disc + `${vnus.disc_unit}`"
                  disabled=""
                />
                <div class="input-group-append">
                  <span class="input-group-text mx-2">-</span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <p class="text-muted">Tiền Giảm</p>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_2"
                  :value="totalDiscount"
                  disabled=""
                />
                <div class="input-group-append">
                  <span class="input-group-text mx-2">=</span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <p class="text-muted">Phí dịch vụ</p>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_2"
                  :value="vnus.total"
                  disabled=""
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="text-sm-end">
              Tổng phí sau khuyễn mãi :
              <span id="result"
                >{{ vnus.subTotal }} - {{ vnus.disc_count }} =
                {{ vnus.total }}</span
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <!-- <div class="row">
      <div class="col-12 mt-3">
        <h3 class="h6" style="color: rgb(56, 118, 29)">Ghi chú</h3>
      </div>
      <div class="col-12">
        <textarea
          :class="{
            'is-invalid': errorSubmit && $v.noted.$invalid,
          }"
          v-model="noted"
          rows="5"
          cols="30"
          class="form-control"
        ></textarea>
        <div v-if="errorSubmit && $v.noted.$error" class="invalid-feedback">
          <span v-if="!$v.noted.required">Cần nhập dữ liệu ghi chú.</span>
        </div>
      </div>
    </div> -->

    <div class="p-2 mt-3">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Hóa đơn</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Total weight</td>
            <td class="text-sm-end">{{ totalLB }} lb</td>
          </tr>
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">{{ service_name }}</td>
          </tr>
          <tr>
            <td>service charge</td>
            <td class="text-sm-end">{{ service_price }}</td>
          </tr>
          <tr>
            <td>Total service fee</td>
            <td class="text-sm-end">{{ total_service_fee }}</td>
          </tr>
          <tr>
            <td>Discount (-)</td>
            <td class="text-sm-end">{{ totalDiscount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row font-size-14">
      <div class="col text-sm-end">
        <strong> Total : {{ total }}</strong>
      </div>
    </div>

   <p class="text-danger m-0">
      <strong>Lưu Ý Quan trọng</strong> Hóa đơn tạo ra sẽ không thể sửa chữa, hãy kiểm tra kĩ trước khi bấm nút tạo hóa đơn.
    </p>
    <button type="button" @click="$router.push({path:'/danh-sach-don-hang'})" class="mx-3 mt-3 btn btn-primary">
     Danh sách đơn hàng
    </button>
    <button type="button" @click="createOrder()" class="mt-3 btn btn-success">
      Tạo hóa đơn
    </button>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      errorSubmit: false,
      noted: "",
      isTax: false,
      price: null,
      isDiscount: false,
      opition: null,
      vnus: {
        name: null,
        lb: 0,
        price: 0,
        subTotal: 0,
        disc: 0,
        disc_unit: "%",
        disc_count: 0,
        total: 0,
      },

      service_name: "",
      service_price: 0,
      totalLB: 0,
      total_service_fee: 0,
      totalTax: 0,
      subTotal: 0,
      totalDiscount: 0,
      total: 0,
      salary: 0,
    };

  },

  validations: {
    opition: {
      required,
    },
    vnus: {
      name: {
        required,
      },
      lb: {
        required,
      },
    },
    noted: {
      // required,
    },
  },

  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getCustomer",
    ]),
  },
  methods: {
    tinhtien() {
      let weight  = Number(this.vnus.lb);

      this.vnus.lb = weight;
    
      if (this.opition) {
        var closest = this.opition.values.reduce(function (prev, curr) {
          return Math.abs(curr.weight - weight) < Math.abs(prev.weight - weight)
            ? curr
            : prev;
        });


        if(weight> this.opition.values[this.opition.values.length-1].weight){
           this.price =  this.opition.price;
             this.totalLB = weight;
            this.service_price = this.price;
            this.service_name = this.getService.name;

            let _total_service_fee = parseFloat((this.price * weight).toFixed(2));
            this.vnus.subTotal = _total_service_fee;

            this.vnus.disc = this.opition.disc;
            this.vnus.disc_unit = this.opition.disc_unit;

        }else{
            this.price = closest.price;
            this.totalLB = weight;
            this.service_price = this.price;
            this.service_name = this.getService.name;

            let _total_service_fee = parseFloat((this.price).toFixed(2));
            this.vnus.subTotal = _total_service_fee;

            this.vnus.disc = this.opition.disc;
            this.vnus.disc_unit = this.opition.disc_unit;

        }

    

      
        if (this.isDiscount) {
          if (this.vnus.disc_unit == "%") {
            this.vnus.disc_count = parseFloat(
              ((this.vnus.disc / 100) * this.vnus.subTotal).toFixed(2)
            );
            this.vnus.total = parseFloat(
              (this.vnus.subTotal - this.vnus.disc_count).toFixed(2)
            );

            this.total_service_fee = this.vnus.subTotal;
            this.subTotal = this.vnus.subTotal;
            this.totalDiscount = this.vnus.disc_count;
            this.total = this.vnus.total;
          } else {
            this.vnus.disc_count = this.vnus.disc;
            this.vnus.total = parseFloat(
              (this.vnus.subTotal - this.vnus.disc_count).toFixed(2)
            );

            this.total_service_fee = this.vnus.subTotal;
            this.subTotal = this.vnus.subTotal;
            this.totalDiscount = this.vnus.disc;
            this.total = this.vnus.total;
          }
        } else {
          this.total_service_fee = this.vnus.subTotal;
          this.subTotal = this.vnus.subTotal;
          this.totalDiscount = 0;
          this.total = this.vnus.subTotal;
        }
      }

    
    },
    createOrder() {
      this.errorSubmit = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorSubmit = true;
        this.$toasted.error("Vui lòng sửa biểu mẫu trước khi xuất hóa đơn");
        return;
      }
      if (this.total > 0) {
        if (user) {
          if (this.getCustomer) {
            if (this.totalDiscount > this.subTotal || this.total < 0) {
              this.$toasted.error("Tổng phí không thể nhỏ hơn tiền giảm");
              return false;
            }

            this.salary = parseFloat((this.totalLB * this.opition.salary).toFixed(2));

            let payload = {
              ...this.getCustomer,
              noted: this.noted,
              lb: this.totalLB,
              type: 2,
              opition: this.opition,
              service_name: this.getService.name,
              shipping_fee: this.service_price,
              fee_unit: this.opition.disc_unit,
              service_price: this.total_service_fee,
              customs_duty: this.totalTax,
              subTotal: this.subTotal,
              discount: this.totalDiscount,
              total: this.total,
              total_tax:0,
              products: [
                {
                  ...this.vnus,
                },
              ],
              salary: this.salary,
            };

            axios({
              url: process.env.VUE_APP_SERVER_URL + "/api/bill/add",
              method: "post",
              headers: {
                authorization: user.token,
              },
              data: payload,
            })
              .then(({ data }) => {
                this.$toasted.success("Hóa đơn đã được xử lý thành công");
                return data;
              })
              .catch((error) => {
                console.log(error);
                error && error.message && error.response.data
                  ? this.$toasted.error(
                      error.response ? error.response.data : error.message
                    )
                  : this.$toasted.error("Lỗi không xác định xảy ra.");
              });
          } else {
            this.$toasted.error(
              "Chưa tải được thông tin khách hàng vui lòng reload lại trang sau đó xuất hóa đơn lại."
            );
          }
        } else {
          this.$toasted.error("Vui lòng đăng nhập để tiếp tục");
        }
      } else {
        this.$toasted.error("Tổng giá trị đơn hàng cần lớn hơn 0");
      }
    },
  },
  mounted() {
    this.service_name = this.getService.name;

  },
};
</script>