<template>
  <div>
    <div class="mb-3" v-if="form">
      <p class="card-title-desc">
        Chỉnh sửa thông tin khách hàng xuất hóa đơn nếu cần
      </p>

      <div class="mb-3">
        <label>Tên người gửi</label>
        <input
          v-model="form.sender_fn"
          type="text"
          class="form-control"
          placeholder="Type something"
          @change="customerChange"
          name="sender_fn"
          :class="{
            'is-invalid': typesubmit && $v.form.sender_fn.$error,
          }"
        />
        <div
          v-if="typesubmit && $v.form.sender_fn.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.sender_fn.required"
            >Giá trị nhập vào là cần thiết.</span
          >
        </div>
      </div>
      <div class="mb-3">
        <label>Số điện thoại người gửi</label>
        <div>
          <input
            v-model="form.sender_phone"
            type="text"
            name="sender_phone"
            class="form-control"
            @change="customerChange"
            :class="{
              'is-invalid': typesubmit && $v.form.sender_phone.$error,
            }"
            placeholder="Enter only digits"
          />
          <div
            v-if="typesubmit && $v.form.sender_phone.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.sender_phone.required"
              >Giá trị nhập vào là cần thiết</span
            >
            <span v-if="!$v.form.sender_phone.numeric"
              >Giá trị nhập vào phải là chữ số.</span
            >
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>E-Mail người gửi</label>
        <div>
          <input
            v-model="form.sender_email"
            type="email"
            name="sender_email"
            class="form-control"
            @change="customerChange"
            :class="{
              'is-invalid': typesubmit && $v.form.sender_email.$error,
            }"
            placeholder="Nhập địa chỉ e-mail"
          />
          <div
            v-if="typesubmit && $v.form.sender_email.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.sender_email.required"
              >Dữ liệu nhập vào là cần thiết.</span
            >
            <span v-if="!$v.form.sender_email.email">Email không phù hợp.</span>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>Địa chỉ người gửi</label>
        <div>
          <input
            v-model="form.sender_address"
            type="text"
            name="sender_address"
            class="form-control"
            @change="customerChange"
            placeholder="Nhập địa chỉ người gửi"
            :class="{
              'is-invalid': typesubmit && $v.form.sender_address.$error,
            }"
          />
          <div
            v-if="typesubmit && $v.form.sender_address.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.sender_address.required"
              >Giá trị nhập vào là được yêu cầu.</span
            >
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>Tên người nhận</label>
        <input
          v-model="form.recipient_fn"
          type="text"
          class="form-control"
          placeholder="Nhập tên người nhận"
          name="recipient_fn"
          @change="customerChange"
          :class="{
            'is-invalid': typesubmit && $v.form.recipient_fn.$error,
          }"
        />
        <div
          v-if="typesubmit && $v.form.recipient_fn.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.recipient_fn.required"
            >Giá trị nhập vào là cần thiết.</span
          >
        </div>
      </div>
      <div class="mb-3">
        <label>Số điện thoại người nhận</label>
        <div>
          <input
            v-model="form.recipient_phone"
            type="text"
            name="recipient_phone"
            class="form-control"
              @change="customerChange"
            :class="{
              'is-invalid': typesubmit && $v.form.recipient_phone.$error,
            }"
            placeholder="Nhập số điện thoại người nhận"
          />
          <div
            v-if="typesubmit && $v.form.recipient_phone.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.recipient_phone.required"
              >Giá trị nhập vào là cần thiết</span
            >
            <span v-if="!$v.form.recipient_phone.numeric"
              >Giá trị nhập vào phải là chữ số.</span
            >
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label>E-Mail người nhận</label>
        <div>
          <input
            v-model="form.recipient_email"
            type="email"
            name="recipient_email"
            class="form-control"
              @change="customerChange"
            :class="{
              'is-invalid': typesubmit && $v.form.recipient_email.$error,
            }"
            placeholder="Nhập địa chỉ e-mail người nhận"
          />
          <div
            v-if="typesubmit && $v.form.recipient_email.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.recipient_email.required"
              >Dữ liệu nhập vào là cần thiết.</span
            >
            <span v-if="!$v.form.recipient_email.email"
              >Email không phù hợp.</span
            >
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>Địa chỉ người nhận</label>
        <div>
          <input
            v-model="form.recipient_address"
            type="text"
            name="recipient_address"
            class="form-control"
            placeholder="Nhập địa chỉ người nhận"
            @change="customerChange"
            :class="{
              'is-invalid': typesubmit && $v.form.recipient_address.$error,
            }"
          />
          <div
            v-if="typesubmit && $v.form.recipient_address.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.recipient_address.required"
              >Giá trị nhập vào là được yêu cầu.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object,
  },
  data() {
    return {
      typesubmit: false,
    };
  },
  methods: {
    customerChange() {
      this.$store.commit("service/setOrderCustomer",Object.assign({}, this.form));
    },
  },
};
</script>