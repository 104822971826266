<template>
  <div>
    <div class="row">
      <div class="col">
        <button
          @click="addItemToProduct"
          class="btn btn-sm btn-info"
          type="button"
        >
          Thêm mục hàng
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 70px">Item</th>
            <th>Tên mặt hàng</th>
            <th>Giá</th>
            <th>- Giảm giá</th>
            <th>x Số lượng</th>
            <th>+ TaxSale</th>
            <th>= Phí dịch vụ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in products" :key="item.name + index">
            <td>{{ index + 1 }}</td>
            <td width="280px">
              <b-form-input
                @change="tinhthue"
                type="text"
                debounce="1000"
                class="form-control form-control-sm"
                name="item_name"
                v-model="item.name"
                placeholder="Nhập tên mặt hàng"
                :class="{
                  'is-invalid':
                    errorSubmit && $v.products.$each[index].name.$error,
                }"
              ></b-form-input>

              <div
                v-if="errorSubmit && $v.products.$each[index].name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.products.$each[index].name.required"
                  >Giá trị nhập vào là được yêu cầu.</span
                >
              </div>
            </td>
            <td width="160px">
              <span>
                <b-form-input
                  debounce="1000"
                  step="0.01"
                  min="0.00"
                  max="10000000.00"
                  type="number"
                  class="form-control form-control-sm"
                  name="item_price"
                  v-model="item.price"
                  placeholder="Nhập giá"
                  @change="tinhthue"
                  :class="{
                    'is-invalid':
                      errorSubmit && $v.products.$each[index].price.$error,
                  }"
                ></b-form-input>

                <div
                  v-if="errorSubmit && $v.products.$each[index].price.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.products.$each[index].price.required"
                    >Giá mục hàng.</span
                  >
                </div>
              </span>
            </td>
            <td width="160px">
              <b-form-input
                debounce="1000"
                step="0.01"
                min="0.00"
                max="10000000.00"
                type="number"
                class="form-control form-control-sm"
                name="item_disc"
                v-model="item.disc"
                placeholder="Nhập giảm giá"
                @change="tinhthue"
              ></b-form-input>
            </td>

            <td width="160px">
              <b-form-input
                debounce="1000"
                step="0.01"
                min="0.00"
                max="10000000.00"
                type="number"
                class="form-control form-control-sm"
                name="item_lb"
                v-model="item.lb"
                placeholder="Nhập số lượng"
                @change="tinhthue"
                :class="{
                  'is-invalid':
                    errorSubmit && $v.products.$each[index].lb.$error,
                }"
              ></b-form-input>

              <div
                v-if="errorSubmit && $v.products.$each[index].lb.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.products.$each[index].price.required"
                  >Số lượng.</span
                >
              </div>
            </td>

            <td width="180px">
              <b-form-input
                @change="tinhthue"
                type="text"
                class="form-control form-control-sm"
                name="item_tax"
                :value="`${saleTax} (${saleTax_unit})`"
                disabled=""
              ></b-form-input>
            </td>
            <td width="180px" class="text-sm-end">
              {{ item.subtotal }}
            </td>
          </tr>

          <tr>
            <td colspan="7">
              <span class="h6">
                Tổng giá trị đơn hàng
                <i class="text-muted font-size-12 text-danger">
              
                  (Giá - giảm giá x số lượng + thuế)
                </i>
              </span>
              <span class="h6 mx-2"> {{ total }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />

    <div class="p-2 mt-3">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Mẫu hóa đơn</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Total Items</td>
            <td class="text-sm-end">{{ totalLB }}</td>
          </tr>
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">{{ service_name }}</td>
          </tr>
          <tr>
            <td>Total Shopping</td>
            <td class="text-sm-end">{{ total_service_fee }}</td>
          </tr>
          <tr>
            <td>Discount</td>
            <td class="text-sm-end">{{ totalDiscount }}</td>
          </tr>
          <tr>
            <td>Tax rate</td>
            <td class="text-sm-end">
              {{ service_price }}{{ getService.shopping.unit }}
            </td>
          </tr>
          <tr>
            <td>Total Tax</td>
            <td class="text-sm-end">{{ totalTax }}</td>
          </tr>
       
          <tr>
            <td>Subtotal</td>
            <td class="text-sm-end">{{ subTotal }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row font-size-14">
      <div class="col text-sm-end">
        <strong> Total : {{ total }}</strong>
      </div>
    </div>
    <hr />
    <p class="text-danger m-0">
      <strong>Lưu Ý Quan trọng</strong> Hóa đơn tạo ra sẽ không thể sửa chữa, hãy kiểm tra kĩ trước khi bấm nút tạo hóa đơn.
    </p>

    <button
      type="button"
      @click="$router.push({ path: '/danh-sach-don-hang' })"
      class="mx-3 mt-3 btn btn-primary"
    >
      Danh sách đơn hàng
    </button>
    <button type="button" @click="createOrder" class="mt-3 btn btn-success">
      Tạo hóa đơn
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      errorSubmit: false,
      noted: "",
      saleTax: 0,
      saleTax_unit: "%",
      sateDisc_unit: "%",
      products: [
        {
          name: null,
          price: 0,
          disc: 0,
          tax: 0,
          lb: 0,
          subtotal: 0,
          total: 0,
        },
      ],
      total: 0,
      service_name: "",
      service_price: 0,
      totalLB: 0,
      total_service_fee: 0,
      totalTax: 0,
      subTotal: 0,
      totalDiscount: 0,
    };
  },
  validations: {
    products: {
      required,
      maxLength: maxLength(15),
      $each: {
        name: {
          required,
        },
        lb: {
          required,
        },
        price: {
          required,
        },
      },
    },
    noted: {
      // required,
    },
  },
  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getCustomer",
    ]),
  },
  methods: {
    tinhthue() {
      let saleTax = this.saleTax;
      let _total = 0;
      let _totalLb = 0;
      let _total_service_fee = 0;
      let _totalTax = 0;
      let _subTotal = 0;
      let _totalDiscount = 0;

      this.products = this.products.map((item) => {
        item.lb = Number(item.lb);
        item.disc = Number(item.disc);
        item.price = Number(item.price);

        item.tax_keep =
          Number(item.lb) * Number(this.getService.shopping.salary);

        if (this.saleTax_unit == "%") {
          if (item.disc > 0) {
            var value = (item.price - item.disc) * item.lb;
            var fee_ship = (item.price*item.lb) * (saleTax / 100);
            var totalSub = value + fee_ship;
            item.subtotal = totalSub;
            var totalSub = value + fee_ship;
            this.totalSub = totalSub;
            _totalTax += fee_ship;
            _total_service_fee +=  Number(item.price * item.lb);
            _subTotal += totalSub;
          } else {
            var value = item.price * item.lb;
            var fee_ship =  (item.price*item.lb) * (saleTax / 100);
            var totalSub = value + fee_ship;
            item.subtotal = totalSub;
            var totalSub = value + fee_ship;
            this.totalSub = totalSub;
            _totalTax += fee_ship;
            _total_service_fee += Number(item.price * item.lb);
            _subTotal += totalSub;
          }
        } else {
          if (item.disc > 0) {
            var value = (item.price - item.disc) * item.lb;
            var fee_ship = item*saleTax;
            var totalSub = value + fee_ship;
            item.subtotal = totalSub;
            var totalSub = value + fee_ship;
            this.totalSub = totalSub;
            _totalTax += fee_ship;
            _total_service_fee +=  Number(item.price * item.lb);
            _subTotal += totalSub;
          } else {
            var value = item.price * item.lb;
            var fee_ship =  item*saleTax;
            var totalSub = value + fee_ship;
            item.subtotal = totalSub;
            var totalSub = value + fee_ship;
            this.totalSub = totalSub;
            _totalTax += fee_ship;
            _total_service_fee +=  Number(item.price * item.lb);
            _subTotal += totalSub;
          }
        }

        // tổng sau thuế
        _total += totalSub;
        // tổng giá trị đơn
        _totalLb += Number(item.lb);
        // tổng khuyễn mãi
        _totalDiscount += Number(item.disc * item.lb);
        // tổng phí

        return item;
      });

      this.totalLB = parseFloat(_totalLb.toFixed(4));
      this.total_service_fee = parseFloat(_total_service_fee.toFixed(4));
      this.totalTax = parseFloat(_totalTax.toFixed(4));
      this.subTotal = parseFloat(_subTotal.toFixed(4));
      this.totalDiscount = _totalDiscount;
      this.total = parseFloat(_total.toFixed(4));
    },

    addItemToProduct() {
      this.products.push({
        name: null,
        price: 0,
        disc: 0,
        tax: 0,
        lb: 1,
        subtotal: 0,
        total: 0,
      });
    },
    createOrder() {
      this.errorSubmit = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorSubmit = true;
        this.$toasted.error("Vui lòng sửa biểu mẫu trước khi xuất hóa đơn");
        return;
      }

      if (this.total > 0) {
        if (user) {
          if (this.getCustomer) {
            if (this.total < 0) {
              this.$toasted.error("Tổng phí không thể thấp hơn 0");
              return false;
            }

            // this.salary = (this.totalLB * this.getOpition.salary).toFixed(2);

            this.salary = 0;
            for (let i = 0; i < this.products.length; i++) {
              let item = this.products[i];
              if (item.tax_keep) {
                this.salary += Number(item.tax_keep);
              }
            }

            let payload = {
              ...this.getCustomer,
              noted: this.noted,
              lb: this.totalLB,
              type: 7,
              opition: this.opition,
              service_name: this.getService.name,
              shipping_fee: this.saleTax,
              fee_unit: this.saleTax_unit,
              service_price: this.total_service_fee,
              customs_duty: this.totalTax,
              subTotal: this.subTotal,
              discount: this.totalDiscount,
              total: this.total,
              products: this.products,
              tax_keep: 0,
              salary: this.salary,
            };

            axios({
              url: process.env.VUE_APP_SERVER_URL + "/api/bill/add",
              method: "post",
              headers: {
                authorization: user.token,
              },
              data: payload,
            })
              .then(({ data }) => {
                this.$toasted.success("Hóa đơn đã được xử lý thành công");
                return data;
              })
              .catch((error) => {
                error && error.message && error.response.data
                  ? this.$toasted.error(
                      error.response ? error.response.data : error.message
                    )
                  : this.$toasted.error("Lỗi không xác định xảy ra.");
              });
          } else {
            this.$toasted.error(
              "Chưa tải được thông tin khách hàng vui lòng reload lại trang sau đó xuất hóa đơn lại."
            );
          }
        } else {
          this.$toasted.error("Vui lòng đăng nhập để tiếp tục");
        }
      } else {
        this.$toasted.error("Tổng giá trị đơn hàng cần lớn hơn 0");
      }
    },
  },

  mounted() {
    if (this.getService) {
      this.service_name = this.getService.name;
      this.saleTax = this.getService.shopping.tax;
      this.service_price = this.saleTax;
      this.saleTax_unit = this.getService.shopping.unit;
      this.sateDisc_unit = this.getService.shopping.disc_unit;
    }
  },
};
</script>