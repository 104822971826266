<template>
  <div>
    <div id="show-money">
      <h5 class="h6">Điền thông tin để tạo hóa đơn</h5>
      <hr />
      <!-- <div class="my-3">
        <label for="Tên mục hàng">Mục hàng</label>
        <b-form-input
          v-model="money.name"
          type="text"
          debounce="300"
          class="form-control"
          name="item_name"
          placeholder="Nhập tên mục hàng"
          :class="{
            'is-invalid': errorSubmit && $v.money.name.$error,
          }"
        >
        </b-form-input>
        <div
          v-if="errorSubmit && $v.money.name.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.money.name.required">Bạn cần nhập tên mục hàng.</span>
        </div>
      </div> -->
      <div class="mb-3">
        <label for="Tên khối lượng hàng">Khối lượng tiền vận chuyển</label>
        <b-form-input
          type="number"
          class="form-control"
          name="item_name"
          placeholder="Nhập khối lượng"
          v-model="money.lb"
          @keyup="tinhtien()"
          :class="{
            'is-invalid': errorSubmit && $v.money.lb.$error,
          }"
        >
        </b-form-input>
        <div v-if="errorSubmit && $v.money.lb.$error" class="invalid-feedback">
          <span v-if="!$v.money.lb.required"
            >Bạn cần nhập khối lượng vận chuyển.</span
          >
        </div>
        <p class="mt-2">Vui lòng nhập vào tối thiểu 100$</p>
      </div>

      <h6 class="text-sm-end">
        Phí dịch vụ :
        <span id="result">
          {{ money.price }}(%) x {{ money.lb }} =
          {{ money.service_price }}</span
        >
      </h6>

      <div class="my-3">
        <input type="checkbox" v-model="isDiscount" @change="tinhtien" />
        Khuyến mãi giảm giá
        <div class="money-disc-show mt-3" v-if="isDiscount">
          <div class="row">
            <div class="col-4">
              <p class="text-muted">Phí dịch vụ</p>
              <div class="input-group mb-3">
                <div class="input-group-prepend mx-2">
                  <div class="input-group-text">$</div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_1"
                  :value="money.service_price"
                  disabled=""
                />
                <div class="input-group-append mx-2">
                  <span class="input-group-text"> - </span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <p class="text-muted">
                Khuyến mãi {{ money.disc + `${money.disc_unit}` }}
              </p>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_2"
                  :value="money.disc_count"
                  disabled=""
                />
                <div class="input-group-append">
                  <span class="input-group-text mx-2">=</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <p class="text-muted">Tổng phí dịch vụ</p>
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  id="disc_input_4"
                  :value="money.total"
                  disabled=""
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <h6 class="text-sm-end">
              Tổng phí sau khuyễn mãi :
              <span id="result"
                >{{ money.subTotal }} - {{ money.disc_count }} =
                {{ money.total }}</span
              >
            </h6>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 mt-3">
        <h3 class="h6" style="color: rgb(56, 118, 29)">Ghi chú</h3>
      </div>
      <div class="col-12">
        <textarea
          :class="{
            'is-invalid': errorSubmit && $v.noted.$invalid,
          }"
          v-model="noted"
          rows="5"
          cols="30"
          class="form-control"
        ></textarea>
        <div v-if="errorSubmit && $v.noted.$error" class="invalid-feedback">
          <span v-if="!$v.noted.required">Ghi chú là cần thiết</span>
        </div>
      </div>
    </div> -->
    <hr />
    <div class="p-2 mt-3">
      <h3 class="h6" style="color: rgb(56, 118, 29)">Mẫu hóa đơn</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Total weight</td>
            <td class="text-sm-end">{{ totalLB }}</td>
          </tr>
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">{{ service_name }}</td>
          </tr>
          <tr>
            <td>service charge</td>
            <td class="text-sm-end">{{ service_price }}</td>
          </tr>
          <tr>
            <td>Total service fee</td>
            <td class="text-sm-end">{{ total_service_fee }}</td>
          </tr>

          <tr>
            <td>Subtotal</td>
            <td class="text-sm-end">{{ subTotal }}</td>
          </tr>
          <tr>
            <td>Discount (-)</td>
            <td class="text-sm-end">{{ totalDiscount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row font-size-14">
      <div class="col text-sm-end">
        <strong> Total : {{ total }}</strong>
      </div>
    </div>
    <hr />

    <p class="text-danger m-0">
      <strong>Lưu Ý Quan trọng</strong> Hóa đơn tạo ra sẽ không thể sửa chữa, hãy kiểm tra kĩ trước khi bấm nút tạo hóa đơn.
    </p>
  

    <button type="button" @click="$router.push({path:'/danh-sach-don-hang'})" class="mx-3 mt-3 btn btn-primary">
     Danh sách đơn hàng
    </button>
      <button type="button" @click="createOrder" class="mt-3 btn btn-success">
      Tạo hóa đơn
    </button>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      errorSubmit: false,

      noted: "",
      isTax: false,
      price: null,
      isDiscount: false,
      opition: null,
      money: {
        name: "Money",
        lb: 0,
        price: 0,
        subTotal: 0,
        service_price: 0,
        disc: 0,
        disc_unit: "%",
        disc_count: 0,
        total: 0,
      },
      service_name: "",
      service_price: 0,
      totalLB: 0,
      total_service_fee: 0,
      totalTax: 0,
      subTotal: 0,
      totalDiscount: 0,
      total: 0,
      salary: 0,
    };
  },
  validations: {
    opition: {
      required,
    },
    money: {
      name: {
        required,
      },
      lb: {
        required,
      },
    },
    noted: {
      // required,
    },
  },
  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getCustomer",
    ]),
  },
  methods: {
    tinhtien() {

      let weight  = Number(this.money.lb);

      this.money.lb = weight;
      
      this.totalLB = weight;

      this.opition = this.getOpition;

      if (this.opition) {
        this.money.price = this.opition.price;
        let fees = weight * (this.opition.price / 100);
        this.money.service_price = parseFloat(fees.toFixed(4));
        this.money.subTotal = Number(this.money.service_price);
        this.money.disc = this.opition.disc;
        this.money.disc_unit = this.opition.disc_unit;
        this.service_price = this.money.service_price;

        let discount = 0;

        if (this.isDiscount) {
          if (this.money.disc_unit == "%") {
            discount = (this.money.disc / 100) * this.money.service_price;
            this.money.disc_count = parseFloat(discount.toFixed(2));
            let total = this.money.subTotal - discount;
            this.money.total = parseFloat(total.toFixed(2));
            this.total_service_fee = this.money.service_price;
            this.subTotal = Number(this.money.subTotal) + Number(this.totalLB);
            this.totalDiscount = this.money.disc_count;
            this.total = this.money.total + Number(this.totalLB);
          } else {
            this.money.disc_count = parseFloat(this.money.disc.toFixed(2));
            let total = this.money.subTotal - this.money.disc;
            this.money.total = parseFloat(total.toFixed(2));

            this.total_service_fee = this.money.service_price;
            this.subTotal = Number(this.money.subTotal) + Number(this.totalLB);
            this.totalDiscount = this.money.disc_count;
            this.total = this.money.total + Number(this.totalLB);
          }
        } else {
          this.total_service_fee = this.money.service_price;
          this.subTotal = Number(this.money.subTotal) + Number(this.totalLB);
          this.totalDiscount = 0;
          this.total = this.money.subTotal;
        }
      }
      //   price = closest.price * weight;
    },
    createOrder() {
      this.errorSubmit = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorSubmit = true;
        this.$toasted.error("Vui lòng sửa biểu mẫu trước khi xuất hóa đơn");
        return;
      }
      if (this.total > 0) {
        if (user) {
          if (this.getCustomer) {
            let noted = this.noted;

            if (this.totalDiscount > this.subTotal || this.total < 0) {
              this.$toasted.error("Tổng phí không thể nhỏ hơn tiền giảm");
              return false;
            }

            if (this.totalLB < this.getOpition.money_min) {
              this.$toasted.error(
                "Vui lòng nhập khối lượng lớn hơn hoặc bằng  " +
                  this.getOpition.money_min
              );
              return;
            }

            this.salary = parseFloat(
              ((this.opition.salary / 100) * this.totalLB).toFixed(2)
            );

            let payload = {
              ...this.getCustomer,
              noted: noted,
              lb: this.totalLB,
              type: 5,
              opition: this.getOpition,
              service_name: this.getService.name,
              shipping_fee: this.service_price,
              fee_unit: this.opition.disc_unit,
              service_price: this.money.subTotal,
              customs_duty: this.totalTax,
              subTotal: this.subTotal,
              discount: this.totalDiscount,
              total: this.total,
              products: [
                {
                  ...this.money,
                },
              ],
              salary: this.salary,
            };

            axios({
              url: process.env.VUE_APP_SERVER_URL + "/api/bill/add",
              method: "post",
              headers: {
                authorization: user.token,
              },
              data: payload,
            })
              .then(({ data }) => {
                this.$toasted.success("Hóa đơn đã được xử lý thành công");
                return data;
              })
              .catch((error) => {
                console.log(error);
                error && error.message && error.response.data
                  ? this.$toasted.error(
                      error.response ? error.response.data : error.message
                    )
                  : this.$toasted.error("Lỗi không xác định xảy ra.");
              });
          } else {
            this.$toasted.error(
              "Chưa tải được thông tin khách hàng vui lòng reload lại trang sau đó xuất hóa đơn lại."
            );
          }
        } else {
          this.$toasted.error("Vui lòng đăng nhập để tiếp tục");
        }
      } else {
        this.$toasted.error("Tổng giá trị đơn hàng cần lớn hơn 0");
      }
    },
  },
  mounted() {
    this.service_name = this.getService.name;
  },
};
</script>