<template>
  <div>
    <div class="row">
      <div class="col">
        <button
          @click="addItemToProduct"
          class="btn btn-sm btn-info"
          type="button"
        >
          Thêm mục hàng
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width="25%">Tên mặt hàng</th>
            <th width="25%">Phân loại</th>
            <th width="25%">Cân nặng</th>
            <th width="10%">Phí gửi</th>
            <th width="10%" class="text-sm-end">Phí dịch vụ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in products" :key="'product-' + index">
            <th width="5%">
              <button
                v-if="index > 0"
                @click="xoaItem(index)"
                class="btn btn-outline-danger btn-sm"
              >
                x
              </button>
            </th>
            <td>
              <b-form-input
                @change="tinhthue"
                type="text"
                debounce="1000"
                class="form-control form-control-sm"
                name="item_name"
                v-model="item.name"
                placeholder="Nhập tên mặt hàng"
                :class="{
                  'is-invalid':
                    errorSubmit && $v.products.$each[index].name.$error,
                }"
              ></b-form-input>
              <div
                v-if="errorSubmit && $v.products.$each[index].name.$invalid"
                class="invalid-feedback"
              >
                <span v-if="!$v.products.$each[index].name.required"
                  >Giá trị nhập vào là được yêu cầu.</span
                >
              </div>
            </td>
            <td>
              <select
                class="form-control form-control-sm"
                v-model="item.tax_opition"
                @change="tinhthue"
                name="item_tax_name"
                :class="{
                  'is-invalid':
                    errorSubmit && $v.products.$each[index].tax_opition.$error,
                }"
              >
                <option :value="null">Chọn phân loại</option>
                <option
                  :value="tax"
                  v-for="tax in getOpition.taxs"
                  :key="tax.name"
                >
                  {{ tax.product_name }}
                </option>
              </select>

              <div
                v-if="
                  errorSubmit && $v.products.$each[index].tax_opition.$invalid
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.products.$each[index].tax_opition.required"
                  >Cần chọn loại sản phẩm</span
                >
              </div>
            </td>
            <td>
            
              <div v-if="!item.tax_opition">
            
                <b-form-input
                  disabled=""
                  debounce="1000"
                  step="0.01"
                  min="0.00"
                  max="10000000.00"
                  type="number"
                  class="form-control form-control-sm"
                  name="item_lb"
                  v-model="item.lb"
                  placeholder="Nhập cân nặng"
                  @change="tinhthue"
                  :class="{
                    'is-invalid':
                      errorSubmit && $v.products.$each[index].lb.$error,
                  }"
                ></b-form-input>
              </div>
              <div v-else>
                <b-form-input
                  v-if="item.tax_opition.product_type"
                  debounce="1000"
                  step="0.01"
                  min="0.00"
                  max="10000000.00"
                  type="number"
                  class="form-control form-control-sm"
                  name="item_lb"
                  v-model="item.lb"
                  placeholder="Nhập cân nặng"
                  @change="tinhthue"
                  :class="{
                    'is-invalid':
                      errorSubmit && $v.products.$each[index].lb.$error,
                  }"
                ></b-form-input>
                <b-form-input
                  v-else
                  disabled=""
                  debounce="1000"
                  step="0.01"
                  min="0.00"
                  max="10000000.00"
                  type="number"
                  class="form-control form-control-sm"
                  name="item_lb"
                  v-model="item.lb"
                  placeholder="Nhập cân nặng"
                  @change="tinhthue"
                  :class="{
                    'is-invalid':
                      errorSubmit && $v.products.$each[index].lb.$error,
                  }"
                ></b-form-input>
              </div>
              <div
                v-if="errorSubmit && $v.products.$each[index].lb.$invalid"
                class="invalid-feedback"
              >
                <span v-if="!$v.products.$each[index].lb.required"
                  >Giá trị nhập vào là được yêu cầu.</span
                >
              </div>
            </td>
            <td>
              <div v-if="!item.tax_opition">
                {{ getOpition.price }} ({{ getOpition.unit }})
              </div>
              <div v-else>
                <span v-if="getOpition && item.tax_opition.product_type">
                  {{ getOpition.price }} ({{ getOpition.unit }})
                  <input
                    type="hidden"
                    name="service_price"
                    :value="`${getOpition.price} (${getOpition.unit}) `"
                  />
                </span>
                <span v-else>
                  0 (%)
                  <input type="hidden" name="service_fees" :value="`0 (%)`" />
                </span>
              </div>
            </td>
            <td class="text-sm-end">
              {{ item.shipping_fee }}
            </td>
          </tr>

          <tr>
            <td colspan="4" class="h6">
              Tổng phí dịch vụ :
              <b>
                {{ total_service_fee }}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <input type="checkbox" v-model="isTax" id="is_tax" @change="tinhthue" />
      <span class="mx-2">Cộng thuế</span>
    </div>

    <div v-if="isTax && products.length > 0">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th width="10%">Item</th>
              <th width="15%">Phân loại</th>
              <th width="20%">Giá trị tính thuế</th>
              <th width="20%" class="text-center">Thuế hải quan</th>
              <!-- <th width="20%" class="text-center">% thuế nhận lương</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in products" :key="'tax-' + index">
              <td>{{ item.name }}</td>

              <td>
                {{ item.tax_opition ? item.tax_opition.product_name : "" }}
              </td>
              <td>
                <input
                  type="number"
                  value="0"
                  class="form-control form-control-sm"
                  disabled="disabled"
                  v-if="
                    (item.tax_opition && item.tax_opition.product_type == 3) ||
                    (item.tax_opition && item.tax_opition.product_type == 4)
                  "
                />

                <b-form-input
                  v-else
                  @change="tinhthue"
                  type="text"
                  debounce="1000"
                  class="form-control form-control-sm"
                  name="item_name"
                  v-model="item.tax_lb"
                  placeholder="Nhập đơn vị tính"
                  :class="{
                    'is-invalid':
                      errorSubmit && $v.products.$each[index].tax_lb.$error,
                  }"
                ></b-form-input>

                <div
                  v-if="errorSubmit && $v.products.$each[index].tax_lb.$invalid"
                  class="invalid-feedback"
                >
                  <pre>{{ $v.products.$each[index] }}</pre>

                  <span v-if="!$v.products.$each[index].tax_lb.required"
                    >Giá trị nhập vào là được yêu cầu.</span
                  >
                  <span v-if="!$v.products.$each[index].tax_lb.numeric"
                    >Gía trị nhập vào phải là số.</span
                  >
                </div>
              </td>

              <td class="text-center">
                <div v-if="item.tax_opition">
                  <span v-if="item.tax_opition.product_type == 3">
                    <b-form-input
                      @change="tinhthue"
                      debounce="1000"
                      step="0.01"
                      min="0.00"
                      max="10000000.00"
                      type="number"
                      class="form-control form-control-sm"
                      name="item_tax"
                      v-model="item.tax"
                      placeholder="Nhập tiền thuế"
                    ></b-form-input>
                  </span>
                  <span v-else>
                    {{ item.tax }}
                  </span>
                </div>
                <div v-else>
                  {{ item.tax }}
                </div>
              </td>
              <!-- <td class="text-center">
                {{item.tax_salary}}
              </td> -->
            </tr>
            <tr>
              <td colspan="4" class="h6">
                Tổng thuế :
                <b>
                  {{ totalTax }}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr />

    <div>
      <input
        type="checkbox"
        v-model="isDiscount"
        id="is_tax"
        @change="tinhthue"
      />
      <span class="mx-2"> Giảm giá </span>
    </div>
    <table class="table" v-if="isDiscount">
      <thead>
        <tr>
          <th width="20%">Phí dịch vụ</th>
          <th width="20%">Tiền giảm giá</th>
          <th width="20%" class="text-sm-end">Giá giảm</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isDiscount">
          <td>
            {{ total_service_fee }}
          </td>
          <td class="text-center">
            <span v-if="getOpition">
              - {{ getOpition.disc }} ({{ getOpition.disc_unit }})
            </span>
            <span v-else> 0 (%) </span>
          </td>
          <td class="text-sm-end">{{ totalDiscount }}</td>
        </tr>
        <!-- <tr v-if="isDiscount">
          <td colspan="3" class="h6">Phí thu sau giảm giá :</td>
          <td class="text-sm-end">
            <b> {{ total }}</b>
          </td>
        </tr> -->
      </tbody>
    </table>

    <!-- <div class="row">
      <div class="col-12 mt-3">
        <h5 class="h6" style="color: rgb(56, 118, 29)">Ghi chú</h5>
      </div>
      <div class="col-12">
        <textarea
          :class="{
            'is-invalid': errorSubmit && $v.noted.$invalid,
          }"
          v-model="noted"
          rows="5"
          cols="30"
          class="form-control"
        ></textarea>
        <div v-if="errorSubmit && $v.noted.$invalid" class="invalid-feedback">
          <span v-if="!$v.noted.required">Cần nhập ghi chú.</span>
        </div>
      </div>
    </div> -->

    <div class="p-2 mt-3">
      <h3 class="h5" style="color: rgb(56, 118, 29)">Hóa đơn</h3>
    </div>
    <div class="table-responsive">
      <!-- {{order.product}} -->
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>Total weight</td>
            <td class="text-sm-end">{{ totalLB }} lb</td>
          </tr>
          <tr>
            <td>Type of service</td>
            <td class="text-sm-end">
              {{ service_name }} [{{ getOpition ? getOpition.name : "" }}]
            </td>
          </tr>
          <tr>
            <td>service charge</td>
            <td class="text-sm-end">{{ service_price }}</td>
          </tr>
          <tr>
            <td>Total service fee</td>
            <td class="text-sm-end">{{ total_service_fee }}</td>
          </tr>
          <tr>
            <td>Customs duty (+)</td>
            <td class="text-sm-end">{{ totalTax }}</td>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td class="text-sm-end">{{ subTotal }}</td>
          </tr>
          <tr>
            <td>Discount (-)</td>
            <td class="text-sm-end">{{ totalDiscount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-3 mt-2 row font-size-14">
      <div class="col text-sm-end">
        <strong> Total : {{ total }}</strong>
      </div>
    </div>
    <hr />

    <p class="text-danger m-0">
      <strong>Lưu Ý Quan trọng</strong> Hóa đơn tạo ra sẽ không thể sửa chữa,
      hãy kiểm tra kĩ trước khi bấm nút tạo hóa đơn
    </p>

    <button
      type="button"
      @click="$router.push({ path: '/danh-sach-don-hang' })"
      class="mx-3 mt-3 btn btn-primary"
    >
      Danh sách đơn hàng
    </button>

    <button type="button" @click="createOrder()" class="mt-3 btn btn-success">
      Tạo hóa đơn
    </button>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";
let user = JSON.parse(localStorage.getItem("user"));
import { required, numeric, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      errorSubmit: false,
      noted: null,
      isTax: false,
      price: null,
      isDiscount: false,
      salary: 0,
      products: [
        {
          name: null,
          lb: 0,
          tax_lb: 0,
          service_price: 0,
          shipping_fee: 0,
          disc: 0,
          tax: 0,
          totalTax: 0,
          isTax: false,
          tax_opition: null,
          subTotal: 0,
          tax_salary: 0,
        },
      ],
      service_name: "",
      service_price: 0,
      totalLB: 0,
      total_service_fee: 0,
      totalTax: 0,
      subTotal: 0,
      totalDiscount: 0,
      total: 0,
      // validate
    };
  },
  validations: {
    products: {
      required,
      maxLength: maxLength(15),
      $each: {
        name: {
          required,
        },
        lb: {
          required,
        },
        tax_lb: {
          required,
        },
        tax_opition: {
          required,
        },
      },
    },
    noted: {
      // required,
    },
  },
  computed: {
    ...mapGetters("service", [
      "getServices",
      "getService",
      "getOpition",
      "getUsvn",
      "getCustomer",
    ]),
  },
  methods: {
    ...mapMutations("service", ["setOrderUsvn"]),
    tinhthue() {
      let opition = this.getOpition;
      let _totalLb = 0;
      let _service_name = this.getService.name;
      let _service_price = opition.price;
      let _total_service_fee = 0;
      let _totalTax = 0;
      let _subTotal = 0;
      let _totalDiscount = 0;
      let _total = 0;

      this.products = this.products.map((item) => {
        item.lb = Number(item.lb);
        item.tax_lb = Number(item.tax_lb);
        item.service_price = _service_price;
        item.shipping_fee = parseFloat((item.lb * _service_price).toFixed(4));
        item.subTotal = item.shipping_fee;
        _totalLb += Number(item.lb);
        _total_service_fee += item.shipping_fee;
        return item;
      });

      if (this.isTax) {
        this.products = this.products.map((item) => {
         if (item.tax_opition && item.tax_opition.product_type) {
         
            switch (item.tax_opition.product_type) {
              case 1:
                if (item.tax_opition.tax_unit == "%") {
                  item.tax = parseFloat(
                    (item.tax_lb * (item.tax_opition.tax / 100)).toFixed(2)
                  );
                } else {
                  item.tax = parseFloat(
                    (item.tax_lb * item.tax_opition.tax).toFixed(2)
                  );
                }

                item.tax_salary = parseFloat(
                  (item.tax_lb * item.tax_opition.salary).toFixed(2)
                );

                break;
              case 2:
                if (item.tax_opition.tax_unit == "%") {
                  item.tax = parseFloat(
                    (item.tax_lb * (item.tax_opition.tax / 100)).toFixed(2)
                  );
                  item.tax = item.tax < 60 ? 60 : item.tax;
                } else {
                  item.tax = parseFloat(
                    item.tax_lb + item.tax_opition.tax.toFixed(2)
                  );
                  item.tax = item.tax < 60 ? 60 : item.tax;
                }

                item.tax_salary = parseFloat(
                  (item.tax_lb * (item.tax_opition.salary / 100)).toFixed(2)
                );

                break;
              case 3:
              // if (item.tax_opition.tax_unit == "%") {
              //     item.tax = parseFloat(
              //       (item.tax_lb * (Number(item.tax) / 100)).toFixed(2)
              //     );
              //   } else {
              //     item.tax = Number(item.tax);
              //   }
                
                item.tax = Number(item.tax);

                item.tax_salary = parseFloat(
                  (item.tax * (item.tax_opition.salary / 100)).toFixed(2)
                );

                break;
            }
          }

          item.subTotal = parseFloat((item.shipping_fee + item.tax).toFixed(4));
          return item;
        });
      }

      this.products = this.products.map((item) => {
        _totalTax += item.tax;
        _subTotal += item.subTotal;
        return item;
      });

      // tính giá thuế

      // item.price = opition.price;

      if (this.isDiscount) {
        if (opition) {
          if (opition.disc_unit == "%") {
            _totalDiscount = _total_service_fee * (opition.disc / 100);
          } else {
            _totalDiscount = opition.disc;
          }
        }

        _total = parseFloat((_subTotal - _totalDiscount).toFixed(2));
      } else {
        _total = _subTotal;
      }

      this.service_name = _service_name;
      this.service_price = _service_price;
      this.totalLB = parseFloat(_totalLb.toFixed(2));
      this.total_service_fee = parseFloat(_total_service_fee.toFixed(2));
      this.totalTax = this.isTax ? parseFloat(_totalTax.toFixed(2)) : 0;
      this.subTotal = parseFloat(_subTotal.toFixed(2));
      this.totalDiscount = parseFloat(_totalDiscount.toFixed(2));
      this.total = parseFloat(_total.toFixed(2));
    },

    setLb(item, tax) {
      console.log(tax);
      // switch()
    },
    xoaItem(_i) {
      this.products = this.products.filter((item, index) => index != _i);
    },
    addItemToProduct() {
      this.products.push({
        name: null,
        lb: 0,
        tax_lb: 0,
        service_price: 0,
        shipping_fee: 0,
        disc: 0,
        tax: 0,
        totalTax: 0,
        isTax: false,
        tax_opition: null,
        subTotal: 0,
      });
    },

    createOrder() {
      this.errorSubmit = false;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorSubmit = true;
        this.$toasted.error("Vui lòng sửa biểu mẫu trước khi xuất hóa đơn");
        return;
      }

     if (this.totalLB < this.getOpition.lb_min) {
        this.$toasted.error(
          "Vui lòng nhập khối lượng lớn hơn hoặc bằng " +
           this.getOpition.lb_min
        );
        return;
      }


      if (this.total > 0) {
        if (user) {
          if (this.getCustomer) {
            if (this.totalDiscount > this.subTotal || this.total < 0) {
              this.$toasted.error("Tổng phí không thể nhỏ hơn tiền giảm");
              return false;
            }

            this.salary = (this.totalLB * this.getOpition.salary).toFixed(2);
            var tax_keep = 0;
            for (let i = 0; i < this.products.length; i++) {
              let item = this.products[i];
              if (item.tax_salary) {
                tax_keep += Number(item.tax_salary);
              }
            }

            let noted = this.noted;
            let payload = {
              ...this.getCustomer,
              noted: noted,
              lb: this.totalLB,
              type: 1,
              opition: this.getOpition,
              service_name: this.getService.name,
              shipping_fee: this.service_price,
              fee_unit: this.getOpition.unit,
              service_price: this.total_service_fee,
              customs_duty: this.totalTax,
              subTotal: this.subTotal,
              discount: this.totalDiscount,
              total: this.total,
              products: this.products,
              salary: this.salary,
              tax_keep: tax_keep,
            };

            axios({
              url: process.env.VUE_APP_SERVER_URL + "/api/bill/add",
              method: "post",
              headers: {
                authorization: user.token,
              },
              data: payload,
            })
              .then(({ data }) => {
                this.$toasted.success("Hóa đơn đã được xử lý thành công");
                return data;
              })
              .catch((error) => {
                error && error.message && error.response.data
                  ? this.$toasted.error(
                      error.response ? error.response.data : error.message
                    )
                  : this.$toasted.error("Lỗi không xác định xảy ra.");
              });
          } else {
            this.$toasted.error(
              "Chưa tải được thông tin khách hàng vui lòng reload lại trang sau đó xuất hóa đơn lại."
            );
          }
        } else {
          this.$toasted.error("Vui lòng đăng nhập để tiếp tục");
        }
      } else {
        this.$toasted.error("Tổng giá trị đơn hàng cần lớn hơn 0");
      }
    },
  },
  mounted() {
    this.service_name = this.getService.name;
  },
};
</script>